export const Features = () => {
  return (
    <div className="mb-8">
      <h1 className="text-center mb-8 text-[48px] leading-[1.0625]">
        More Features
      </h1>

      <div className="grid grid-cols-12 gap-16">
        <div className="col-span-4 card space-y-4">
          <img src="money-time.png" alt="" />
          <h5 className="leading-[1.6525] text-[#0B0021]">Pay as you go</h5>
          <p className="sub-p text-[#55486F]">
            Only get billed when we place an Ad in your videos. We'll never take
            a percentage of your sponsorship contracts.
          </p>
        </div>

        <div className="col-span-4 card space-y-4">
          <img src="monitor-mobile.png" alt="" />
          <h5 className="leading-[1.6525] text-[#0B0021]">Platform agnostic</h5>
          <p className="sub-p text-[#55486F]">
            Qlik.me's AI software can insert virtual billboards on videos of all
            shapes & sizes - TikTok, Reels, YouTube, & more.
          </p>
        </div>

        <div className="col-span-4 card space-y-4">
          <img src="people.png" alt="" />
          <h5 className="leading-[1.6525] text-[#0B0021]">Managed service</h5>
          <p className="sub-p text-[#55486F]">
            Qlik.me's influencer sponsorship pro's will work with your team to
            negotiate upsales on prospective sponsorship contracts.
          </p>
        </div>

        <div className="col-span-4 card space-y-4">
          <img src="chart.png" alt="" />
          <h5 className="leading-[1.6525] text-[#0B0021]">Comprehensive analytics</h5>
          <p className="sub-p text-[#55486F]">
            Qlik.me combines data pulled from official platform API’s & optionally 
            integrates with measurement platforms to provide actionable insights.
          </p>
        </div>

        <div className="col-span-4 card space-y-4">
          <img src="like.png" alt="" />
          <h5 className="leading-[1.6525] text-[#0B0021]">Custom models on request</h5>
          <p className="sub-p text-[#55486F]">
            Qlik.me builds customized Ad insertion models for enterprise customers 
            capable of new synthetic Ad formats like virtual hand-helds, clothing
            try-on, or furniture placement.
          </p>
        </div>

        <div className="col-span-4 card space-y-4">
          <img src="cup.png" alt="" />
          <h5 className="leading-[1.6525] text-[#0B0021]">Premium placements</h5>
          <p className="sub-p text-[#55486F]">
            Users spend time consuming content from the creators they love. Qlik.me 
            seamlessly places your brand in the highest attention real estate on the 
            internet.
          </p>
        </div>
      </div>
    </div>
  );
};
