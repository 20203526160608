export const ProductDemo = () => {
  return (
    <div className="mb-8">
      <div className="flex justify-between mb-8">
        <h1 className="text-left text-[48px] leading-[1.0625]">
          Product Demo
        </h1>

        <a href="#" className="text-purple-600 flex items-center hover:underline">
          <p className="font-bold text-purple-600">See More</p>
          <svg className="ml-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor"><path d="M435.3 267.3L446.6 256l-11.3-11.3-168-168L256 65.4 233.4 88l11.3 11.3L385.4 240 16 240 0 240l0 32 16 0 369.4 0L244.7 412.7 233.4 424 256 446.6l11.3-11.3 168-168z"/></svg>
        </a>
      </div>

      <div className="grid grid-cols-3 gap-14">
        <div className="relative max-w-[350px] justify-self-start">
          <img className="relative z-20" src="iphone.png" />
          <div className="absolute top-0 left-0 w-full h-full rounded-[8%] p-[1.5%] bg-black z-10 overflow-hidden">
            <div className="relative top-0 left-0 h-full w-auto">
              <video autoPlay loop muted>
                <source src="videos/video_1.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>

        <div className="relative max-w-[350px] justify-self-center">
          <img className="relative z-20" src="iphone.png" />
          <div className="absolute top-0 left-0 w-full h-full rounded-[8%] p-[1.5%] bg-black z-10 overflow-hidden">
            <div className="relative top-0 left-0 h-full w-auto">
              <video autoPlay loop muted>
                <source src="videos/video_2.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>

        <div className="relative max-w-[350px] justify-self-end">
          <img className="relative z-20" src="iphone.png" />
          <div className="absolute top-0 left-0 w-full h-full rounded-[8%] p-[1.5%] bg-black z-10 overflow-hidden">
            <div className="relative top-0 left-0 h-full w-auto">
              <video autoPlay loop muted>
                <source src="videos/video_3.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
