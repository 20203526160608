import { Advantages } from "../sections/Advantages";
import { Features } from "../sections/Features";
import { Hero } from "../sections/Hero";
import { ProductDemo } from "../sections/ProductDemo";
import { Revenue } from "../sections/Revenue";

export const Index = () => {
  return (
    <div className="container m-auto">
      <div className="row">
        <Hero />
        <div className="flex flex-col space-y-[200px]">
          <Advantages />
          <Revenue />
          <Features />
          <ProductDemo />
        </div>
      </div>
    </div>
  );
};
