import revenueLoungeAnimation from "../animations/src/lounge.json";
import revenueShareAnimation from "../animations/src/revenue-share.json";
import sponseredAnimation from "../animations/src/sponsered-posts.json";
import Lottie from "lottie-react";

export const Revenue = () => {
  return (
    <div className="flex flex-col space-y-[69px]">
      <center>
        <h1 className="text-[48px] leading-[1.0625]">
          Maximize revenue,
          <br /> minimize effort.
        </h1>
      </center>
      <div>
        <div className="grid grid-cols-12">
          <div className="col-span-5">
            <Lottie animationData={sponseredAnimation} />
          </div>

          <div className="col-span-2 justify-center items-center flex flex-col">
            <div className="line flex-1 opacity-0"></div>
            <div className="w-[30px] h-[30px] border-4 border-purple-600 mb-8 mt-8 border-box rounded-full"></div>
            <div className="line flex-1"></div>
          </div>
          <div className="col-span-5 flex flex-col justify-center">
            <div className="w-[56px] bg-purple-600 px-5 py-2.5 mb-4 text-base leading-[22px] text-white font-bold rounded-[10px] text-center">
              01
            </div>
            <h5 className="leading-[1.72] text-[#0B0021]">
              Qlik.me upsells your upcoming sponsorship contracts by monetizing
              unsponsored content.
            </h5>
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-5 flex flex-col justify-center text-right">
            <div>
              <div className="float-right light-purple w-[56px] px-[18px] py-2.5 mb-4 text-base leading-[22px] text-white font-bold rounded-[10px] text-center">
                02
              </div>
            </div>
            <h5 className="leading-[1.72] text-[#0B0021]">
              Influencers earn up to 50% more revenue with our zero-effort, AI
              generated Ads.
            </h5>
          </div>

          <div className="col-span-2 justify-center items-center flex flex-col">
            <div className="line flex-1"></div>
            <div className="rounded-full w-[30px] h-[30px] border-[6px] light-purple light-purple-border mb-8 mt-8 border-box border-opacity-30"></div>
            <div className="line flex-1"></div>
          </div>

          <div className="col-span-5">
            <Lottie animationData={revenueLoungeAnimation} />
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-5">
            <Lottie animationData={revenueShareAnimation} />
          </div>

          <div className="col-span-2 justify-center items-center flex flex-col">
            <div className="line flex-1"></div>
            <div className="rounded-full w-[30px] h-[30px] border-[6px] light-purple light-purple-border mb-8 mt-8 border-box border-opacity-30"></div>
            <div className="line flex-1 opacity-0"></div>
          </div>

          <div className="col-span-5 flex flex-col justify-center">
            <div className="light-purple w-[56px] px-[18px] py-2.5 mb-4 text-base leading-[22px] text-white font-bold rounded-[10px] text-center">
              03
            </div>
            <h5 className="leading-[1.72] text-[#0B0021]">
              Pay a small, flat fee for our software. No revenue sharing!
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};
