export const Demo = () => {
  return (
    <div className="container m-auto">
      <div className="py-20 flex flex-col space-y-[18px]">
        <h1 className="text-[48px] leading-[1.2625] text-[#0B0021]">
          Want a free demo with your product?
        </h1>
        <p className="text-xl leading-[1.8925] text-[#55486F]">
          Qlik.me will create a 3D, digital representation of your product & run
          it through our AI, <br />
          generating a set of demo images with real influencers.
        </p>

        <div className="flex pt-[31px] space-x-6">
          <button className="bg-[#7F43FF] px-8 py-[23px] text-white rounded-[10px] font-bold text-base leading-[22px]">
            See Qlik.me with my product
          </button>
          <button className="border-[#0B0021] border-2 px-8 py-[21px] rounded-[10px] font-bold text-base leading-[22px] text-[#0B0021]">
            More Examples
          </button>
        </div>
      </div>
    </div>
  );
};
