import { Demo } from "../sections/Demo";
import Lottie from "lottie-react";
import headerAnimation from "../animations/src/header.json";
// import { headerAnimationStyle } from "./Header";

const animationStyle = {
  position: "absolute",
  zIndex: "-1",
  width: "100%",
  height: "840px",
  overflow: "hidden",
};

export const Footer = () => {
  return (
    <div
      className="w-full mt-[200px] footer-gradient"
      style={{ backgroundImage: 'url("footer.png")' }}
    >
      <Lottie
        style={animationStyle}
        animationData={headerAnimation}
        rendererSettings={{ id: "footer-animation" }}
      />
      <Demo />
      <div className="container m-auto py-10">
        <div className="grid grid-cols-12">
          <div className="col-span-3 flex flex-col">
            <h5>Company</h5>
            <ul className="space-y-4 mt-4">
              <li>
                <button>About</button>
              </li>
              <li>
                <button>Careers</button>
              </li>
            </ul>
          </div>
          <div className="col-span-3">
            <h5>Policies</h5>
            <ul className="space-y-4 mt-4">
              <li>
                <button>Privacy Policy</button>
              </li>
              <li>
                <button>Terms of Notice</button>
              </li>
              <li>
                <button>Privacy Notice</button>
              </li>
            </ul>
          </div>
          <div className="col-span-3">
            <h5>Client Resources</h5>
            <ul className="space-y-4 mt-4">
              <li>
                <button>Client Support</button>
              </li>
              <li>
                <button>User Dashboard</button>
              </li>
              <li>
                <button>Demo Request</button>
              </li>
            </ul>
          </div>
          <div className="col-span-3">
            <h5>Category</h5>
            <ul className="space-y-4 mt-4">
              <li>
                <button>Blog</button>
              </li>
              <li>
                <button>Resources</button>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex my-8 mt-20">
          <div className="flex-grow">
            <h3>Qlik.me</h3>
            <span>
              Insert a small description of your company
              <br /> in here or your company address.
            </span>
          </div>

          <div className="">
            <img className="inline-block mr-6" src="twitter.png" alt="" />
            <img className="inline-block mr-6" src="discord.png" alt="" />
            <img className="inline-block" src="linkedin.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
