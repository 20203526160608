import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import lottie from "lottie-web";
import { useLayoutEffect } from "react";
import afterEngagementBubble from "../animations/src/after_engagement_bubble.json";
import afterBillboardBubble from "../animations/src/after_billboard_bubble.json";
import afterMoneyBubble from "../animations/src/after_money_bubble.json";


export const Advantages = () => {
  gsap.registerPlugin(ScrollTrigger);

  const lottieScrollTrigger = (vars) => {
    let target        = gsap.utils.toArray(vars.target)[0],
        scrollTrigger = (callback) => ({
          trigger: target,
          pin: true,
          start: "top 20%",
          end: "+=2800",
          scrub: 1,
          marker: true,
          onUpdate: callback
        }),
        context       = gsap.context && gsap.context();

    let createAnimation = (animation) => {
      let target = gsap.utils.toArray(animation.target)[0];
      return lottie.loadAnimation({ container: target, ...animation.properties });
    };

    // #1 Create animations.
    for(let animation of vars.animations) {
      animation.instance = createAnimation(animation);
      animation.playhead = { frame: 0 };
    }
    
    // #2 Wait for the content to be loaded.
    document.addEventListener("DOMContentLoaded", () => {
      // #3 Create the animation timeline.
      let createTimeline = () => {
        let timeline = gsap.timeline({
          ease: "none",
          scrollTrigger: scrollTrigger(self => {
            let progress = self.progress.toFixed(3);
            let direction = self.direction;
            let velocity = self.getVelocity();

            console.log("Progress: " + progress + ", Velocity: " + velocity + ", Direction: " + direction);

            // #4 Set the animations.
            for(let entry of vars.animations) {
              let animation = entry.instance;

              // #5 Calculate the frames.
              let startProgress = entry.frames.start || 0.0;
              let endProgress = entry.frames.end || 0.0;

              let rangeProgress = 1;
              rangeProgress -= startProgress;
              rangeProgress -= endProgress;

              let calculateFrames = () => {
                let nextProgress = progress;
                nextProgress = progress > startProgress ? nextProgress - startProgress : 0;
                nextProgress = progress > rangeProgress ? nextProgress - endProgress : nextProgress;
                nextProgress /= rangeProgress;
                return nextProgress;
              }

              // #6 Apply the frames.
              animation.frameTween && animation.frameTween.pause();

              animation.frameTween = !entry.frames.custom 
                ? gsap.to(entry.playhead, {
                  duration: () => {
                    if(entry.frames.duration) return entry.frames.duration;

                    let duration = Math.min(Math.abs(velocity), 8_000);
                    duration = Math.min(1 - (duration / 8_000), 0.5);
                    return duration;
                  },
                  ease: "power1.inOut",
                  frame: () => {
                    let nextProgress = calculateFrames();

                    if(entry.frames.snap) nextProgress = gsap.utils.snap(entry.frames.snap, nextProgress);

                    return Math.floor(nextProgress * (animation.totalFrames - 1));
                  },
                  onUpdate: () => {
                    animation.goToAndStop(entry.playhead.frame, true);
                  }
                })
                : gsap.to(entry.playhead, {
                  duration: () => {
                    let duration = Math.min(Math.abs(velocity), 8_000);
                    duration = Math.min(1 - (duration / 8_000), 0.5);
                    return duration;
                  },
                  ease: "power1.inOut",
                  onUpdate: () => {
                    entry.frames.custom(animation, calculateFrames());
                  }
                });
            }
          })
        });

        return () => {
          for(let { instance } of vars.animations) {
            instance.destroy && instance.destroy();
          }
        }
      };

      // Store the timeline in the context.
      context && context.add ? context.add(createTimeline) : createTimeline();

      // Sort and refresh scroll triggers in case this caused a layout shift.
      ScrollTrigger.sort();
      ScrollTrigger.refresh();
    });
  };

  useLayoutEffect(() => {
    lottieScrollTrigger({
      target: "#advantage-container",
      animations: [
        {
          target: "#wheel-animation",
          properties: {
            name: "advantage_wheel",
            renderer: "svg",
            loop: false,
            autoplay: false,
            path: "https://qlik-v2-frontend.pages.dev/after_base.json",
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          },
          frames: {
            start: 0,
            snap: [0, 0.25, 0.5, 0.75, 1]
          }
        },
        {
          target: "#lottie-engagement",
          properties: {
            name: "engagement_step",
            renderer: "svg",
            loop: true,
            autoplay: false,
            animationData: afterEngagementBubble,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          },
          frames: {
            start: 0.125,
            duration: 1.25,
            custom: (animation, progress) => {
              if(progress <= 0 && !animation.isPaused) {
                let currentFrame = Math.min(15, animation.currentFrame);

                animation.setLoop(false);
                animation.pause()
                animation.resetSegments(true);
                animation.goToAndPlay(currentFrame, true);
                animation.setDirection(-1);
              } else if(progress > 0 && animation.isPaused) {
                animation.setLoop(true);
                animation.setDirection(1);
                animation.playSegments([[0, 200], [100, 200]], true);
              }
            }
          }
        },
        {
          target: "#lottie-billboard",
          properties: {
            name: "billboard_step",
            renderer: "svg",
            loop: true,
            autoplay: false,
            animationData: afterBillboardBubble,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          },
          frames: {
            start: 0.375,
            duration: 1.25,
            custom: (animation, progress) => {
              if(progress <= 0 && !animation.isPaused) {
                let currentFrame = Math.min(15, animation.currentFrame);

                animation.setLoop(false);
                animation.pause()
                animation.resetSegments(true);
                animation.goToAndPlay(currentFrame, true);
                animation.setDirection(-1);
              } else if(progress > 0 && animation.isPaused) {
                animation.setLoop(true);
                animation.setDirection(1);
                animation.playSegments([[0, 100], [20, 200]], true);
              }
            }
          }
        },
        {
          target: "#lottie-money",
          properties: {
            name: "money_step",
            renderer: "svg",
            loop: true,
            autoplay: false,
            animationData: afterMoneyBubble,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          },
          frames: {
            start: 0.625,
            duration: 1.25,
            custom: (animation, progress) => {
              if(progress <= 0 && !animation.isPaused) {
                let currentFrame = Math.min(15, animation.currentFrame);

                animation.setLoop(false);
                animation.pause()
                animation.resetSegments(true);
                animation.goToAndPlay(currentFrame, true);
                animation.setDirection(-1);
              } else if(progress > 0 && animation.isPaused) {
                animation.setLoop(true);
                animation.setDirection(1);
                animation.playSegments([[0, 200], [100, 200]], true);
              }
            }
          }
        }
      ]
    });
  }, []);
  
  return (
    <div className="h-[3400px]">
      <div className="m-auto" id="advantage-container">
        <div className="row">
          <div className="grid grid-cols-12">
            {/* The wheel animation (left side). */}
            <div className="col-span-6 flex flex-col">
              <div className="relative h-fit">
                <div id="wheel-animation" className="max-h-[800px]"></div>
                <div
                  id="after_engagement_bubble"
                  className="bubble w-[30%] top-[67%] left-[43%] absolute"
                >
                  <div id="lottie-engagement"></div>
                </div>
                <div
                  id="after_billboard_bubble"
                  className="bubble w-[30%] top-[54%] left-[12%] absolute"
                >
                  <div id="lottie-billboard"></div>
                </div>
                <div
                  id="after_money_bubble"
                  className="bubble w-[30%] top-[21%] left-0 absolute"
                >
                  <div id="lottie-money"></div>
                </div>
              </div>
            </div>

            {/* The wheel text (right side). */}
            <div className="col-span-6 p-20 flex flex-col">
              <h1 className="text-[48px] leading-[1.0625]">Our advantages</h1>
              <p className="mt-[11px]">
                Current solutions require influencers to manage time consuming
                sponsorship creation that disrupts videos & hurts engagement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};