import { Brands } from "../common/Brands";
import Lottie from "lottie-react";
import heroAnimation from "../animations/src/hero.json";

export const Hero = () => {
  return (
    <div className="container pb-40 mt-[-40px]">
      <div className="grid grid-cols-12">
        <div className="col-span-4 h-fit my-auto">
          <h1 className="text-5xl tracking-tight mb-6">
            Double the ad revenue, zero ad breaks.
          </h1>
          <p>
            Influencers & agencies use Qlik.me to place digital billboards
            inside content at scale - monetizing unsponsored videos without
            disruption.
          </p>
          <button className="px-[38px] py-[23px] bg-purple-600 text-white font-bold text-base leading-[22px] rounded-[10px] mt-[42px]">
            Shorter Button
          </button>
        </div>

        <div className="col-span-8">
          <Lottie animationData={heroAnimation} />
        </div>
      </div>

      <Brands />
    </div>
  );
};
