import "./App.css";
import { Footer } from "./components/common/Footer";
import { Header } from "./components/common/Header";
import { Index } from "./components/pages/Index";

function App() {
  return (
    <div className="App">
      <Header />
      <Index />
      <Footer />
    </div>
  );
}

export default App;
