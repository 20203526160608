export const Navigation = () => {
  return (
    <nav className="flex justify-c\enter align-center items-center text-sm w-full">
      <ul className="flex flex-grow space-x-8 justify-center">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a className="flex items-center" href="#">
            Advantages <img src="chevron.png" className="h-[6px] ml-2" alt="" />
          </a>
        </li>
        <li>
          <a href="#">Features</a>
        </li>
        <li>
          <a href="#">Contact</a>
        </li>
      </ul>

      <button className="text-purple-600 border-2 text-sm font-bold button px-10 py-4 border-purple-600 rounded-[10px]">
        Register
      </button>
    </nav>
  );
};
