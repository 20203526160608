import { Logo } from "./Logo";
import { Navigation } from "./Navigation";
import Lottie from "lottie-react";
import headerAnimation from "../animations/src/header.json";

export const headerAnimationStyle = {
  position: "absolute",
  zIndex: "-1",
  width: "100%",
  height: "auto",
};

export const Header = () => {
  return (
    <>
      <Lottie style={headerAnimationStyle} animationData={headerAnimation} />
      <div className="container m-auto">
        <div className="pt-20 flex">
          <Logo />
          <Navigation />
        </div>
      </div>
    </>
  );
};
